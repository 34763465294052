@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery img {
  border-radius: 8px;
  max-height: 60vh;
}

.image-gallery-content:not(.fullscreen) {
  /* background-color: #f5f5f5; grey[100] */
}

.image-gallery-content:not(.fullscreen) img {
  object-fit: cover;
  object-position: center;
  height: 350px;
}

.image-gallery-thumbnails-wrapper img {
  object-fit: contain;
  object-position: center;
  max-height: 55px;
}

.image-gallery .image-gallery-fullscreen-button {
  right: -8px;
  bottom: -8px;
}

.image-gallery .image-gallery-fullscreen-button > svg {
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 8px;
}

html {
  height: 100%;
  width: 100%;
  background-color: white;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  background-color: #fff; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.gmnoprint > div > button {
  padding: 0 12px !important;
  font-size: 15px !important;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.no-transition {
  transition: none !important;
}

.feature-tbd::after {
  content: "(în lucru)";
  font-size: 10px;
  font-weight: 600;
  color: gray;
  margin-left: 4px;
}
